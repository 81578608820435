module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div\n  class="gmf-profile-container panel"\n  ng-show="ctrl.active">\n\n  <div class="spinner-profile" ng-show="ctrl.isLoading">\n    <i class="fa fa-spin">\n      ' +
((__t = (require('gmf/icons/spinner.svg?viewbox&height=3rem'))) == null ? '' : __t) +
'\n    </i>\n  </div>\n\n  <div\n    class="ngeo-profile"\n    ng-if="!ctrl.isErrored && !ctrl.isLoading"\n    ngeo-profile="ctrl.profileData"\n    ngeo-profile-highlight="ctrl.profileHighlight"\n    ngeo-profile-options="::ctrl.profileOptions">\n  </div>\n\n  <ul\n    class="gmf-profile-legend"\n    ng-if="!ctrl.isErrored && !ctrl.isLoading">\n\n    <li ng-repeat="name in ::ctrl.getLayersNames()">\n      <i\n        class="fa fa-minus"\n        ng-style="ctrl.getStyle(name)"></i>\n      {{name | translate}}\n      <span ng-if="ctrl.currentPoint.elevations[name] != null">\n      {{ctrl.currentPoint.elevations[name]}}&nbsp;{{ctrl.currentPoint.yUnits}}\n      </span>\n    </li>\n  </ul>\n\n  <div\n    class="gmf-profile-export btn-group dropup"\n    ng-show="!ctrl.isErrored && !ctrl.isLoading">\n\n    <a\n      class="dropdown-toggle"\n      href=""\n      ng-show="ctrl.profileData.length !== 0"\n      data-toggle="dropdown"\n      aria-expanded="false">\n      {{\'Export\' | translate}}\n    </a>\n\n    <ul\n      class="dropdown-menu dropdown-menu-right"\n      role="menu">\n\n      <li>\n      <a\n        href=""\n        ng-click="::ctrl.downloadCsv()">\n        <i class="fa fa-table"></i>&nbsp;{{\'Download CSV\' | translate}}</a>\n      </li>\n    </ul>\n  </div>\n\n  <div\n    class="gmf-profile-error"\n    ng-show="ctrl.isErrored">\n\n    <p>{{\'The profile service does not respond, please try later.\' | translate}}</p>\n  </div>\n  <div\n    class="close"\n    ng-click="ctrl.line = null">&times;</div>\n</div>\n';

}
return __p
}