module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div\n  class="btn-group"\n>\n  <button\n    data-toggle="tooltip"\n    title="{{\'While active, click on the map to issue a query\' | translate}}"\n    class="btn btn-default"\n    ng-class="{active: $ctrl.ngeoQueryModeSelector.mode === \'click\'}"\n    ng-click="$ctrl.ngeoQueryModeSelector.mode = \'click\'"\n  >\n    ' +
((__t = (require('ngeo/icons/point.svg?viewbox&height=1em'))) == null ? '' : __t) +
'\n  </button>\n  <button\n    data-toggle="tooltip"\n    title="{{\'While active, click twice the map to issue a query using a box\' | translate}}"\n    class="btn btn-default"\n    ng-class="{active: $ctrl.ngeoQueryModeSelector.mode === \'drawbox\'}"\n    ng-click="$ctrl.ngeoQueryModeSelector.mode = \'drawbox\'"\n  >\n    ' +
((__t = (require('ngeo/icons/rectangle.svg?viewbox&height=1em'))) == null ? '' : __t) +
'\n  </button>\n  <button\n    data-toggle="tooltip"\n    title="{{\'While active, click on the map multiple times to draw a polygon, which will issue a query using it\' | translate}}"\n    class="btn btn-default"\n    ng-class="{active: $ctrl.ngeoQueryModeSelector.mode === \'drawpolygon\'}"\n    ng-click="$ctrl.ngeoQueryModeSelector.mode = \'drawpolygon\'"\n  >\n    ' +
((__t = (require('ngeo/icons/polygon.svg?viewbox&height=1em'))) == null ? '' : __t) +
'\n  </button>\n</div>\n\n<div class="ngeo-query-panel-actions">\n  <div class="form-check">\n    <label class="form-check-label">\n      <input\n        type="radio"\n        class="form-check-input"\n        ng-model="$ctrl.ngeoQueryModeSelector.action"\n        value="add"\n      >\n        <span>{{\'Add\' | translate }}</span>\n      </input>\n    </label>\n  </div>\n  <div class="form-check">\n    <label class="form-check-label">\n      <input\n        type="radio"\n        class="form-check-input"\n        ng-model="$ctrl.ngeoQueryModeSelector.action"\n        value="remove"\n      >\n        <span>{{\'Remove\' | translate }}</span>\n      </input>\n    </label>\n  </div>\n  <div class="form-check">\n    <label class="form-check-label">\n      <input\n        type="radio"\n        class="form-check-input"\n        ng-model="$ctrl.ngeoQueryModeSelector.action"\n        value="replace"\n      >\n        <span>{{\'Replace\' | translate }}</span>\n      </input>\n    </label>\n  </div>\n</div>\n\n<div class="ngeo-query-panel-help">\n  <span\n    ng-show="$ctrl.ngeoQueryModeSelector.mode === \'click\'"\n  >{{\'Query by "click" on the map is currently active. All queryable layers, including WMS and WFS, will return results.\' | translate }}</span>\n  <span\n    ng-show="$ctrl.ngeoQueryModeSelector.mode === \'drawbox\'"\n  >{{\'Query by "box" on the map is currently active. Not all queryable layers will return results. Only those that support WFS will do so.\' | translate }}</span>\n  <span\n    ng-show="$ctrl.ngeoQueryModeSelector.mode === \'drawpolygon\'"\n  >{{\'Query by "polygon" on the map is currently active. Not all queryable layers will return results. Only those that support WFS will do so.\' | translate }}</span>\n</div>\n';

}
return __p
}